import './Contacts.css';

import officePhoto from '../../images/office.png'
import tgIcon from '../../images/telegramIcon.png'


function Contacts() {

  return (
    <div id='office' className='contacts'>
      <div className='office-container'>
        <div>
          <h2 className='contacts-header'>Приглашаем вас в гости</h2>
          <p className='contacts-text'>У нас есть просторная и бесплатная парковка, где вы легко и без проблем найдете удобное место для своего автомобиля.</p>
        </div>
        <img className='contacts-office' src={officePhoto} alt='officePhoto' />
      </div>
      <div id='contacts' className='contacts-info'>
        <div className='info-container'>
          <h2 className='contacts-header'>Контакты</h2>
          <div className='contacts-container'>
            <p className='contacts-head'>Режим работы</p>
            <p className='contacts-line'>Работаем ежедневно с 10:00 до 23:00</p>
          </div>
          <div className='contacts-container'>
            <p className='contacts-head'>Адрес</p>
            <p className='contacts-line'>경기도 안산시 단원구 선부로 35</p>
          </div>
          <div className='contacts-container'>
            <p className='contacts-head'>Телефон</p>
            <div className='contacts-telephone'>
              <p className='contacts-line'>+8210-7609-4555</p>
              <img className='contacts-tg' src={tgIcon} alt='telegramIcon' />
            </div>

          </div>

        </div>
        <div className='map-container'>
          <a target='blank' className='contacts-map' href='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198.2600584975872!2d126.7995517077765!3d37.33869843320016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b6f1d807de2e1%3A0x830e65615d1002a8!2z7Jio642U67aA7Iqk!5e0!3m2!1sru!2skr!4v1709748778524!5m2!1sru!2skr'><iframe className='contacts-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198.2600584975872!2d126.7995517077765!3d37.33869843320016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b6f1d807de2e1%3A0x830e65615d1002a8!2z7Jio642U67aA7Iqk!5e0!3m2!1sru!2skr!4v1709748778524!5m2!1sru!2skr" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></a>
        </div>
      </div>
      <p className='partners'>Наши партнеры</p>
    </div>
  );
}

export default Contacts;
